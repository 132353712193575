import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/views/Home";
import NotFound from "@/views/404.vue";

// import Feature from "@/views/About/Feature.vue";
// import News from "@/views/About/News.vue";
// import Origin from "@/views/About/Origin.vue";
// import Publish from "@/views/About/Publish.vue";
// import PublicMemberPrivacy from "@/views/About/MemberPrivacy.vue";
// import PublicPrivacy from "@/views/About/Privacy.vue";

// import Schedule from "@/views/Notice/Schedule.vue";
// import Process from "@/views/Notice/Process.vue";
// import Score from "@/views/Notice/Score.vue";
// import Fee from "@/views/Notice/Fee.vue";
// import Award from "@/views/Notice/Award.vue";
// import Scope from "@/views/Notice/Scope.vue";
// import Rule from "@/views/Notice/Rule.vue";
// import ExamRoom from "@/views/Notice/ExamRoom.vue";


// import FormTeam from "@/views/Examinee/FormTeam.vue"
// import Privacy from "@/views/Examinee/Privacy.vue"
// import Personal from "@/views/Examinee/Personal.vue";
// import SalesReader from "@/views/Examinee/SalesReader.vue";
// import Team from "@/views/Examinee/Team.vue";
// import Pay from "@/views/Payment/Pay.vue";
import ApplyOpen from "@/views/Payment/oOpen.vue";
import ApplyEnd from "@/views/Payment/oEnd.vue";

// import MemberManage from "@/views/Member/Manage.vue";
// import MemberOrderTrack from "@/views/Member/OrderTrack.vue";
// import MemberDataManage from "@/views/Member/DataManage.vue";
// import MemberData from "@/views/Member/Data.vue";

// import OrderTrack from "@/views/Examinee/OrderTrack.vue";
// import DataManage from "@/views/Examinee/DataManage.vue";
// import InquiryResult from "@/views/Examinee/InquiryResult.vue";
// import QADownLoad from "@/views/Examinee/QADownLoad.vue";
// import InquiryOverYears from "@/views/Examinee/InquiryOverYears.vue";
// import Reissuance from "@/views/Examinee/Reissuance.vue";
// import Register from "@/views/Examinee/Register.vue";
// import RegisterFinish from "@/views/Examinee/RegisterFinish.vue";

// import EventInfo from "@/views/FAQ/EventInfo.vue";
// import ExamContent from "@/views/FAQ/ExamContent.vue";
// import Important from "@/views/FAQ/Important.vue";

// import Personal2 from "@/views/Elite/Personal.vue";
// import Group2 from "@/views/Elite/Group.vue";
// import Statistics from "@/views/Elite/Statistics.vue";

// import Expert from "@/views/Talk/Expert.vue";
// import Parent from "@/views/Talk/Parent.vue";

// import SprintIntroduce from "@/views/Sprint/Introduce.vue";
// import ReadSprintIntroduce from "@/views/Sprint/ReadSprintIntroduce.vue";
// import SprintChapter from "@/views/Sprint/Chapter.vue";
// import SprintStep from "@/views/Sprint/Step.vue";
// import SprintFaq from "@/views/Sprint/Faq.vue";
import CampIntroduce from "@/views/Camp/Introduce.vue";
import FeeRefundPolicy from '@/views/Notice/FeeRefundPolicy.vue'; // 确保路径正确
import RefundPage from '@/views/Member/RefundPage.vue'; // 退费页面组件
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
   component: Home,
  meta: {
    title: '康軒卓越盃全國競賽－全國最多小學生參加的數學競賽',
    metaTags: [
      {
        name: 'description',
        content: '康軒卓越盃是現行公開舉辦的國小數學及閱讀賽事中，歷史最悠久、參加人數最多的指標性競賽！自2008年起開辦數學競賽及閱讀競賽，本賽事是為國小一年級至七年級學生舉行的大型競賽。'
      },
      {
        property: 'og:description',
        content: '康軒卓越盃是現行公開舉辦的國小數學及閱讀賽事中，歷史最悠久、參加人數最多的指標性競賽！自2008年起開辦數學競賽及閱讀競賽，本賽事是為國小一年級至七年級學生舉行的大型競賽。'
      }
    ]
  }
  },
  {
    path: "/404",
    name: "404",
    component: NotFound,
  },
  // 關於卓越盃
  {
    path: "/About/News",
    name: "News",
    // component: News,
    component:()=>import('../views/About/News.vue'),
    meta: {
      title: '康軒卓越盃最新消息－報名時程表、場地及成績查詢',
      metaTags: [
        {
          name: 'description',
          content: '一年一度的卓越盃開始報名嘍！競賽日期：11/20(日) 早鳥優惠：即日起到 7/31 日 報名數學，原價 800元，早鳥報名只要 700 元 報名數學+中文閱讀素養，原價 1300 元，只要 1200 唷～誠摯的邀請各位爸比媽咪讓孩子一同參與盛大的數學與閱讀競賽唷！'
        },
        {
          property: 'og:description',
          content: '一年一度的卓越盃開始報名嘍！競賽日期：11/20(日) 早鳥優惠：即日起到 7/31 日 報名數學，原價 800元，早鳥報名只要 700 元 報名數學+中文閱讀素養，原價 1300 元，只要 1200 唷～誠摯的邀請各位爸比媽咪讓孩子一同參與盛大的數學與閱讀競賽唷！'
        }
      ]
    }
  },
  {
    path: "/About/Origin",
    name: "Origin",
    // component: Origin,
    component:()=>import('../views/About/Origin.vue'),
    meta: {
      title: '關於卓越盃源起－全國最多小學生參加的數學和閱讀競賽',
      metaTags: [
        {
          name: 'description',
          content: '自2008年起開辦卓越盃數學競賽及閱讀競賽，本賽事是為國小一年級至七年級學生舉行的大型競賽。每年在競賽前會在全國進行「預試」作業，藉由孩子作答結果修正考題難易度與命題方式，提升考題的公正性及鑑別度。'
        },
        {
          property: 'og:description',
          content: '自2008年起開辦卓越盃數學競賽及閱讀競賽，本賽事是為國小一年級至七年級學生舉行的大型競賽。每年在競賽前會在全國進行「預試」作業，藉由孩子作答結果修正考題難易度與命題方式，提升考題的公正性及鑑別度。'
        }
      ]
    }
  },
  {
    path: "/About/Feature",
    name: "Feature",
    // component: Feature,
    component:()=>import('../views/About/Feature.vue')
  },
  {
    path: "/About/Publish",
    name: "Publish",
    // component: Publish,
    component:()=>import('../views/About/Publish.vue'),
    meta: {
      title: '康軒卓越盃數學競賽試題精選－國小數學 1~6年級 附解答',
      metaTags: [
        {
          name: 'description',
          content: '卓越盃數學競賽試題精選內含歷屆正式考題和考前模擬試題，並模擬正式試卷和作答卷，及提供各題詳解說明。'
        },
        {
          property: 'og:description',
          content: '卓越盃數學競賽試題精選內含歷屆正式考題和考前模擬試題，並模擬正式試卷和作答卷，及提供各題詳解說明。'
        }
      ]
    }
  },
  {
    path: "/About/MemberPrivacy",
    name: "PublicMemberPrivacy",
    // component: PublicMemberPrivacy,
    component:()=>import('../views/About/MemberPrivacy.vue'),
    meta: {
      title: '康軒卓越盃全國競賽－會員使用條款',
      metaTags: [
        {
          name: 'description',
          content: '康軒卓越盃是現行公開舉辦的國小數學及閱讀賽事中，歷史最悠久、參加人數最多的指標性競賽！自2008年起開辦數學競賽及閱讀競賽，本賽事是為國小一年級至七年級學生舉行的大型競賽。'
        },
        {
          property: 'og:description',
          content: '康軒卓越盃是現行公開舉辦的國小數學及閱讀賽事中，歷史最悠久、參加人數最多的指標性競賽！自2008年起開辦數學競賽及閱讀競賽，本賽事是為國小一年級至七年級學生舉行的大型競賽。'
        }
      ]
    }
  },
  {
    path: "/About/Privacy",
    name: "PublicPrivacy",
    // component: PublicPrivacy,
    component:()=>import('../views/About/Privacy.vue'),
    meta: {
      title: '康軒卓越盃全國競賽－隱私權政策',
      metaTags: [
        {
          name: 'description',
          content: '為確保消費者個人資料、隱私及消費者權益之保護，【卓越盃全國競賽】（以下簡稱本站）於服務過程中將依法蒐集、處理及利用您於本站所提供之個人資料。 如果您對於本站隱私權政策或與個人資料有關之相關事項有任何疑問，可以利用 客服信箱（kc-test@knsh.com.tw）和本站聯絡，我們將儘快回覆說明。'
        },
        {
          property: 'og:description',
          content: '為確保消費者個人資料、隱私及消費者權益之保護，【卓越盃全國競賽】（以下簡稱本站）於服務過程中將依法蒐集、處理及利用您於本站所提供之個人資料。 如果您對於本站隱私權政策或與個人資料有關之相關事項有任何疑問，可以利用 客服信箱（kc-test@knsh.com.tw）和本站聯絡，我們將儘快回覆說明。'
        }
      ]
    }
  },
  // 競賽須知
  {
    path: "/Notice/Schedule",
    name: "Schedule",
    // component: Schedule,
    component:()=>import('../views/Notice/Schedule.vue'),
    meta: {
      title: '康軒卓越盃競賽須知－最新考試時程',
      metaTags: [
        {
          name: 'description',
          content: '康軒卓越盃即將開始，考試時程都在這！報名時間：6/15(四)-10/05(四)(早鳥:6/15~7/31)考場、試場及准考證號碼公布：11/06(一)上午11時競賽日期：11/12(日)題目及解答公布：11/13(一)上午11時'
        },
        {
          property: 'og:description',
          content:  '康軒卓越盃即將開始，考試時程都在這！報名時間：6/15(四)-10/05(四)(早鳥:6/15~7/31)考場、試場及准考證號碼公布：11/06(一)上午11時競賽日期：11/12(日)題目及解答公布：11/13(一)上午11時'
        }
      ]
    }
  },
  {
    path: "/Notice/Process",
    name: "Process",
    // component: Process,
    component:()=>import('../views/Notice/Process.vue'),
    meta: {
      title: '康軒卓越盃競賽須知－最新報名流程和報名資格',
      metaTags: [
        {
          name: 'description',
          content: '數學競賽報名資格：一至六年級學生；閱讀競賽報名資格：二至六年級學生 (閱讀競賽僅限已報名數學競賽者加考，不可單獨報考)；報名年級請依當年9月份就讀年級選擇報考年級，如：9月後就讀四年級，就報名四年級。'
        },
        {
          property: 'og:description',
          content: '數學競賽報名資格：一至六年級學生；閱讀競賽報名資格：二至六年級學生 (閱讀競賽僅限已報名數學競賽者加考，不可單獨報考)；報名年級請依當年9月份就讀年級選擇報考年級，如：9月後就讀四年級，就報名四年級。'
        }
      ]
    }
  },
  {
    path: "/Notice/Team",
    name: "Process",
    // component: Process,
    component:()=>import('../views/Notice/Team.vue'),
    meta: {
      title: '康軒卓越盃競賽須知－最新報名流程和報名資格',
      metaTags: [
        {
          name: 'description',
          content: '數學競賽報名資格：一至六年級學生；閱讀競賽報名資格：二至六年級學生 (閱讀競賽僅限已報名數學競賽者加考，不可單獨報考)；報名年級請依當年9月份就讀年級選擇報考年級，如：9月後就讀四年級，就報名四年級。'
        },
        {
          property: 'og:description',
          content: '數學競賽報名資格：一至六年級學生；閱讀競賽報名資格：二至六年級學生 (閱讀競賽僅限已報名數學競賽者加考，不可單獨報考)；報名年級請依當年9月份就讀年級選擇報考年級，如：9月後就讀四年級，就報名四年級。'
        }
      ]
    }
  },
  {
    path: "/Notice/Score",
    name: "Score",
    // component: Score,
    component:()=>import('../views/Notice/Score.vue'),
    meta: {
      title: '康軒卓越盃競賽須知－作答計分方式',
      metaTags: [
        {
          name: 'description',
          content: '卓越盃數學競賽總分為200分，分年級以及分兩時段－基礎挑戰題 (40分鐘)和素養挑戰題 (25分鐘)。數學競賽皆以最終答案判定對錯，答案卷不需寫上計算過程，答案全對才給分，無局部斟酌給分。數學競賽排名以總分排定，若遇總分同分則並列名次，下一名從缺。'
        },
        {
          property: 'og:description',
          content: '卓越盃數學競賽總分為200分，分年級以及分兩時段－基礎挑戰題 (40分鐘)和素養挑戰題 (25分鐘)。數學競賽皆以最終答案判定對錯，答案卷不需寫上計算過程，答案全對才給分，無局部斟酌給分。數學競賽排名以總分排定，若遇總分同分則並列名次，下一名從缺。'
        }
      ]
    }
  },
  {
    path: "/Notice/Fee",
    name: "Fee",
    // component: Fee,
    component:()=>import('../views/Notice/Fee.vue'),
    meta: {
      title: '康軒卓越盃競賽須知－報名費用、退費方法和報名贈品',
      metaTags: [
        {
          name: 'description',
          content: '卓越盃數學競賽報名費用：新台幣800元整；數學競賽 + 閱讀競賽報名費用：新台幣1,300元整 (閱讀競賽僅限已報名數學競賽者加考，不可單獨報考)。 繳交報名資料並成功繳費後，始完成報名手續。報名成功後若因故無法參與競賽，得見退費申請辦法，請仔細考慮後再報名，謝謝！'
        },
        {
          property: 'og:description',
          content: '卓越盃數學競賽報名費用：新台幣800元整；數學競賽 + 閱讀競賽報名費用：新台幣1,300元整 (閱讀競賽僅限已報名數學競賽者加考，不可單獨報考)。 繳交報名資料並成功繳費後，始完成報名手續。報名成功後若因故無法參與競賽，得見退費申請辦法，請仔細考慮後再報名，謝謝！'
        }
      ]
    }
  },
  {
    path: '/notice/FeeRefundPolicy',
    name: 'FeeRefundPolicy',
    component: FeeRefundPolicy,
    meta: { layout: 'empty' } // 使用元信息指定不使用默认布局
  },
  {
    path: '/notice/RefundPolicy',
    name: 'RefundPolicy',
    component: ()=>import('../views/Notice/RefundPolicy.vue'),
   // meta: { layout: 'empty' } // 使用元信息指定不使用默认布局
  },
  {
    path: '/notice/Refund',
    name: 'Refund',
    component: ()=>import('../views/Notice/Refund.vue'),
    meta: { layout: 'empty' } // 使用元信息指定不使用默认布局
  },
  {
    path: "/Notice/AwardSingle",
    name: "Award",
    // component: Award,
    component:()=>import('../views/Notice/AwardSingle.vue'),
    meta: {
      title: '康軒卓越盃競賽須知－數學競賽個人競賽獎項／獎勵內容',
      metaTags: [
        {
          name: 'description',
          content: '數學競賽個人競賽全國各年級排名第1名將獲得獎盃乙座、獎狀乙紙、獎學金5,000元以及康軒學習雜誌半年份。本競賽各獎項不重複頒獎，故若已獲數學優選獎(含)以上獎項，則不再頒發進步獎。'
        },
        {
          property: 'og:description',
          content: '數學競賽個人競賽全國各年級排名第1名將獲得獎盃乙座、獎狀乙紙、獎學金5,000元以及康軒學習雜誌半年份。本競賽各獎項不重複頒獎，故若已獲數學優選獎(含)以上獎項，則不再頒發進步獎。'
        }
      ]
    }
  },
  {
    path: "/Notice/AwardTeam",
    name: "Award",
    // component: Award,
    component:()=>import('../views/Notice/AwardTeam.vue'),
    meta: {
      title: '康軒卓越盃競賽須知－數學競賽團體競賽獎項／獎勵內容',
      metaTags: [
        {
          name: 'description',
          content: '數學競賽團體競賽全國各年級排名第1名將獲獎盃乙座、個人獎牌、獎學金10,000元。組隊的考生，除參與個人競賽外，亦同時具團體競賽資格。若團體競賽積分相同，則同列該獎項，次一獎項則不再頒發。如有未盡事宜，主辦單位保留修改、變更活動內容細節之權利，且不另行通知。'
        },
        {
          property: 'og:description',
          content: '數學競賽團體競賽全國各年級排名第1名將獲獎盃乙座、個人獎牌、獎學金10,000元。組隊的考生，除參與個人競賽外，亦同時具團體競賽資格。若團體競賽積分相同，則同列該獎項，次一獎項則不再頒發。如有未盡事宜，主辦單位保留修改、變更活動內容細節之權利，且不另行通知。'
        }
      ]
    }
  },
  {
    path: "/Notice/AwardRead",
    name: "Award",
    // component: Award,
    component:()=>import('../views/Notice/AwardRead.vue'),
    meta: {
      title: '康軒卓越盃競賽須知－閱讀競賽獎項／獎勵內容',
      metaTags: [
        {
          name: 'description',
          content: '金質獎-各年級達全數成績前5%將獲得『金質獎』獎盃乙座、獎狀乙紙。本競賽各獎項不重複頒獎，故若已獲閱讀銅質獎(含)以上獎項，則不再頒發進步獎。進步獎採計僅限2024年報考年級較2023報考年級高一個年級者。若遇兩位同分，則同列該獎項，而次一獎項不再頒發，以此類推。'
        },
        {
          property: 'og:description',
          content: '金質獎-各年級達全數成績前5%將獲得『金質獎』獎盃乙座、獎狀乙紙。本競賽各獎項不重複頒獎，故若已獲閱讀銅質獎(含)以上獎項，則不再頒發進步獎。進步獎採計僅限2024年報考年級較2023報考年級高一個年級者。若遇兩位同分，則同列該獎項，而次一獎項不再頒發，以此類推。'
        }
      ]
    }
  },
  {
    path: "/Notice/Scope",
    name: "Scope",
    // component: Scope,
    component:()=>import('../views/Notice/Scope.vue'),
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: '康軒卓越盃數學競賽依據臺灣數學課綱的內容命題，以9月開學後就讀年級為準，出題範圍為前一個年級與開學後2個月。一年級命題範圍有：數數看、分與合、比比看、分類、圖形與空間、邏輯推理。'
        },
        {
          property: 'og:description',
          content: '康軒卓越盃數學競賽依據臺灣數學課綱的內容命題，以9月開學後就讀年級為準，出題範圍為前一個年級與開學後2個月。一年級命題範圍有：數數看、分與合、比比看、分類、圖形與空間、邏輯推理。'
        }
      ]
    }
  },
  {
    path: "/Notice/ScopeMath1",
    name: "ScopeMath1",
    // component: Scope,
    component:()=>import('../views/Notice/ScopeMath1.vue'),
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: '康軒卓越盃數學競賽依據臺灣數學課綱的內容命題，以9月開學後就讀年級為準，出題範圍為前一個年級與開學後2個月。一年級命題範圍有：數數看、分與合、比比看、分類、圖形與空間、邏輯推理。'
        },
        {
          property: 'og:description',
          content: '康軒卓越盃數學競賽依據臺灣數學課綱的內容命題，以9月開學後就讀年級為準，出題範圍為前一個年級與開學後2個月。一年級命題範圍有：數數看、分與合、比比看、分類、圖形與空間、邏輯推理。'
        }
      ]
    }
  },
  {
    path: "/Notice/ScopeMath2",
    name: "ScopeMath2",
    // component: Scope,
    component:()=>import('../views/Notice/ScopeMath2.vue'),
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: '康軒卓越盃數學競賽依據臺灣數學課綱的內容命題，以9月開學後就讀年級為準，出題範圍為前一個年級與開學後2個月。二年級命題範圍有：整數與序數、加法和減法、比長短、時間和日期、圖形與空間、分類。'
        },
        {
          property: 'og:description',
          content: '康軒卓越盃數學競賽依據臺灣數學課綱的內容命題，以9月開學後就讀年級為準，出題範圍為前一個年級與開學後2個月。二年級命題範圍有：整數與序數、加法和減法、比長短、時間和日期、圖形與空間、分類。'
        }
      ]
    }
  },
  {
    path: "/Notice/ScopeMath3",
    name: "ScopeMath3",
    // component: Scope,
    component:()=>import('../views/Notice/ScopeMath3.vue'),
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: '康軒卓越盃數學競賽依據臺灣數學課綱的內容命題，以9月開學後就讀年級為準，出題範圍為前一個年級與開學後2個月。三年級命題範圍有：整數與加減、乘法、分分看與分數、時間和日期、量的關係與計算、圖形與空間。'
        },
        {
          property: 'og:description',
          content: '康軒卓越盃數學競賽依據臺灣數學課綱的內容命題，以9月開學後就讀年級為準，出題範圍為前一個年級與開學後2個月。三年級命題範圍有：整數與加減、乘法、分分看與分數、時間和日期、量的關係與計算、圖形與空間。'
        }
      ]
    }
  },
  {
    path: "/Notice/ScopeMath4",
    name: "ScopeMath4",
    // component: Scope,
    component:()=>import('../views/Notice/ScopeMath4.vue'),
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: '康軒卓越盃數學競賽依據臺灣數學課綱的內容命題，以9月開學後就讀年級為準，出題範圍為前一個年級與開學後2個月。四年級命題範圍有：整數與加減、乘法和除法、分數和小數、量的關係與計算、圖形與空間、找規律。'
        },
        {
          property: 'og:description',
          content: '康軒卓越盃數學競賽依據臺灣數學課綱的內容命題，以9月開學後就讀年級為準，出題範圍為前一個年級與開學後2個月。四年級命題範圍有：整數與加減、乘法和除法、分數和小數、量的關係與計算、圖形與空間、找規律。'
        }
      ]
    }
  },
  {
    path: "/Notice/ScopeMath5",
    name: "ScopeMath5",
    // component: Scope,
    component:()=>import('../views/Notice/ScopeMath5.vue'),
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: '康軒卓越盃數學競賽依據臺灣數學課綱的內容命題，以9月開學後就讀年級為準，出題範圍為前一個年級與開學後2個月。五年級命題範圍有：整數與概數、分數和小數、整數四則、圖形和形體、量的關係與計算、數量規律。'
        },
        {
          property: 'og:description',
          content: '康軒卓越盃數學競賽依據臺灣數學課綱的內容命題，以9月開學後就讀年級為準，出題範圍為前一個年級與開學後2個月。五年級命題範圍有：整數與概數、分數和小數、整數四則、圖形和形體、量的關係與計算、數量規律。'
        }
      ]
    }
  },
  {
    path: "/Notice/ScopeMath6",
    name: "ScopeMath6",
    // component: Scope,
    component:()=>import('../views/Notice/ScopeMath6.vue'),
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: '康軒卓越盃數學競賽依據臺灣數學課綱的內容命題，以9月開學後就讀年級為準，出題範圍為前一個年級與開學後2個月。六年級命題範圍有：公因數與公倍數、整數計算、分數、小數、百分率、平面圖形、立體形體、時間和重量。'
        },
        {
          property: 'og:description',
          content: '康軒卓越盃數學競賽依據臺灣數學課綱的內容命題，以9月開學後就讀年級為準，出題範圍為前一個年級與開學後2個月。六年級命題範圍有：公因數與公倍數、整數計算、分數、小數、百分率、平面圖形、立體形體、時間和重量。'
        }
      ]
    }
  },
  {
    path: "/Notice/ScopeMath7",
    name: "ScopeMath7",
    // component: Scope,
    component:()=>import('../views/Notice/ScopeMath7.vue'),
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: '康軒卓越盃數學競賽依據臺灣數學課綱的內容命題，以9月開學後就讀年級為準，出題範圍為前一個年級與開學後2個月。七年級命題範圍有：'
        },
        {
          property: 'og:description',
          content: '康軒卓越盃數學競賽依據臺灣數學課綱的內容命題，以9月開學後就讀年級為準，出題範圍為前一個年級與開學後2個月。七年級命題範圍有：'
        }
      ]
    }
  },
  {
    path: "/Notice/ScopeRead",
    name: "ScopeRead",
    // component: Scope,
    component:()=>import('../views/Notice/ScopeRead.vue'),
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    }
  },
  {
    path: "/Notice/Rule",
    name: "Rule",
    // component: Rule,
    component:()=>import('../views/Notice/Rule.vue'),
    meta: {
      title: '康軒卓越盃競賽須知－考生應試規則與注意事項',
      metaTags: [
        {
          name: 'description',
          content: '康軒卓越盃應考當日請務必遵守規則。請留意攜帶(出)物品規定，考生於競賽開始15分鐘後，禁止進入試場，並視同棄權。陪考家長務必請於09：20前離開試場。每節考試間隔時間，考生皆不得離開教室，將由試務人員引導考生上廁所及休息。'
        },
        {
          property: 'og:description',
          content: '康軒卓越盃應考當日請務必遵守規則。請留意攜帶(出)物品規定，考生於競賽開始15分鐘後，禁止進入試場，並視同棄權。陪考家長務必請於09：20前離開試場。每節考試間隔時間，考生皆不得離開教室，將由試務人員引導考生上廁所及休息。'
        }
      ]
    }
  },
  {
    path: "/Notice/ExamRoom",
    name: "ExamRoom",
    // component: ExamRoom,
    component:()=>import('../views/Notice/ExamRoom.vue'),
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    }
  },
  {
    path: "/Notice/Registration",
    name: "Registration",
    // component: ExamRoom,
    component:()=>import('../views/Notice/Registration.vue'),
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    }
  },
  // 考生專區
  {
    path: "/Examinee/FormTeam",
    name: "FormTeam",
    // component: FormTeam,
    component:()=>import('../views/Examinee/FormTeam.vue')
  }, 
  {
    path: "/Examinee/Privacy",
    name: "Privacy",
    // component: Privacy,
    component:()=>import('../views/Examinee/Privacy.vue')
  },
  {
    path: "/Examinee/Personal",
    name: "Personal",
    // component: Personal,
    component:()=>import('../views/Examinee/Personal.vue')
  },
  {
    path: "/Examinee/SalesReader",
    name: "SalesReader",
    // component: SalesReader,
    component:()=>import('../views/Examinee/SalesReader.vue')
  },
  {
    path: "/Payment/Pay",
    name: "Pay",
    // component: Pay,
    component:()=>import('../views/Payment/Pay.vue')
  },
  {
    path: "/Payment/AtmPay",
    name: "AtmPay",
    // component: AtmPay,
    component:()=>import('../views/Payment/AtmPay.vue')
  },
  {
    path: "/Payment/ApplyOpen",
    name: "ApplyOpen",
    component: ApplyOpen,
  },
  {
    path: "/Payment/ApplyEnd",
    name: "ApplyEnd",
    component: ApplyEnd,
  },
  {
    path: "/Examinee/OrderTrack",
    name: "OrderTrack",
    // component: OrderTrack,
    component:()=>import('../views/Examinee/OrderTrack.vue'),
    meta: {
      title: '康軒卓越盃考生專區－考生個人查詢',
      metaTags: [
        {
          name: 'description',
          content: '考生可查詢報名成功與否。請注意：卓越盃不會主動以電話通知您成績或得獎資訊！若接獲不明電話，請勿提供任何個人資料及金融資訊，有任何競賽問題，請主動撥打卓越盃「客服專線02-86651745」洽詢或至「聯絡我們」留言。'
        },
        {
          property: 'og:description',
          content: '考生可查詢報名成功與否。請注意：卓越盃不會主動以電話通知您成績或得獎資訊！若接獲不明電話，請勿提供任何個人資料及金融資訊，有任何競賽問題，請主動撥打卓越盃「客服專線02-86651745」洽詢或至「聯絡我們」留言。'
        }
      ]
    }
  },
  {
    path: "/Examinee/DataManage",
    name: "DataManage",
    // component: DataManage,
    component:()=>import('../views/Examinee/DataManage.vue'),
    meta: {
      title: '康軒卓越盃考生專區－考生資料查詢',
      metaTags: [
        {
          name: 'description',
          content: '考生可查詢報名資料正確與否。請注意：卓越盃不會主動以電話通知您成績或得獎資訊！若接獲不明電話，請勿提供任何個人資料及金融資訊，有任何競賽問題，請主動撥打卓越盃「客服專線02-86651745」洽詢或至「聯絡我們」留言。'
        },
        {
          property: 'og:description',
          content: '考生可查詢報名資料正確與否。請注意：卓越盃不會主動以電話通知您成績或得獎資訊！若接獲不明電話，請勿提供任何個人資料及金融資訊，有任何競賽問題，請主動撥打卓越盃「客服專線02-86651745」洽詢或至「聯絡我們」留言。'
        }
      ]
    }
  },
  {
    path: "/Examinee/Team",
    name: "Team",
    // component: Team,
    component:()=>import('../views/Examinee/Team.vue')
  },
  {
    path: "/Examinee/InquiryResult",
    name: "InquiryResult",
    // component: InquiryResult,
    component:()=>import('../views/Examinee/InquiryResult.vue')
  },
  {
    path: "/Examinee/QADownLoad",
    name: "QADownLoad",
    // component: QADownLoad,
    component:()=>import('../views/Examinee/QADownLoad.vue')
  },
  {
    path: "/Examinee/InquiryOverYears",
    name: "InquiryOverYears",
    // component: InquiryOverYears,
    component:()=>import('../views/Examinee/InquiryOverYears.vue'),
    meta: {
      title: '康軒卓越盃考生專區－歷年成績及得獎查詢',
      metaTags: [
        {
          name: 'description',
          content: '提供考生歷年成績及得獎查詢。請注意：卓越盃不會主動以電話通知您成績或得獎資訊！若接獲不明電話，請勿提供任何個人資料及金融資訊，有任何競賽問題，請主動撥打卓越盃「客服專線02-86651745」洽詢或至「聯絡我們」留言。'
        },
        {
          property: 'og:description',
          content: '提供考生歷年成績及得獎查詢。請注意：卓越盃不會主動以電話通知您成績或得獎資訊！若接獲不明電話，請勿提供任何個人資料及金融資訊，有任何競賽問題，請主動撥打卓越盃「客服專線02-86651745」洽詢或至「聯絡我們」留言。'
        }
      ]
    }
  },
  {
    path: "/Examinee/Reissuance",
    name: "Reissuance",
    // component: Reissuance,
    component:()=>import('../views/Examinee/Reissuance.vue')
  },
  {
    path: "/Examinee/Register",
    name: "Register",
    // component: Register,
    component:()=>import('../views/Examinee/Register.vue')
  },
  {
    path: "/Examinee/RegisterFinish",
    name: "RegisterFinish",
    // component: RegisterFinish,
    component:()=>import('../views/Examinee/RegisterFinish.vue')
  },
  {
    path: "/Member/Manage",
    name: "MemberManage",
    // component: MemberManage,
    component:()=>import('../views/Member/Manage.vue')
  },
  {
    path: "/Member/OrderTrack",
    name: "MemberOrderTrack",
    // component: MemberOrderTrack,
    component:()=>import('../views/Member/OrderTrack.vue')
  },
  {
    path: "/Member/RefundPage",
    name: "RefundPage",
    component: RefundPage
  },
  {
    path: "/Member/DataManage",
    name: "MemberDataManage",
    // component: MemberDataManage,
    component:()=>import('../views/Member/DataManage.vue')
  },
  {
    path: "/Member/Data",
    name: "MemberData",
    // component: MemberData,
    component:()=>import('../views/Member/Data.vue')
  },
  {
    path: "/Member/ExamManage",
    name: "MemberExamManage",
    // component: MemberDataManage,
    component:()=>import('../views/Member/ExamManage.vue')
  },
  {
    path: "/Member/Modify",
    name: "MemberModify",
    // component: MemberDataManage,
    component:()=>import('../views/Member/Modify.vue')
  },
  {
    path: "/Member/QADownload",
    name: "MemberQADownload",
    // component: MemberQADownload,
    component:()=>import('../views/Member/QADownload.vue')
  },
  {
    path: "/Member/MockTestDownload",
    name: "MemberMockTestDownload",
    // component: MemberQADownload,
    component:()=>import('../views/Member/MockTestDownload.vue')
  },
  {
    path: "/Member/TestQuestionDownload",
    name: "TestQuestionDownload",
    // component: MemberQADownload,
    component:()=>import('../views/Member/TestQuestionDownload.vue')
  },
  // 常見問題
  {
    path: "/FAQ/EventInfo",
    name: "EventInfo",
    // component: EventInfo,
    component:()=>import('../views/FAQ/EventInfo.vue'),
    meta: {
      title: '康軒卓越盃常見問題－賽事資訊一覽',
      metaTags: [
        {
          name: 'description',
          content: '卓越盃何時舉辦？招生對象及報名資格？幼兒園的孩子可以報名卓越盃嗎？卓越盃的報名費用多少？讓我們來回答您的問題，或於上班時間撥打客服專線02-8665-1745。'
        },
        {
          property: 'og:description',
          content: '卓越盃何時舉辦？招生對象及報名資格？幼兒園的孩子可以報名卓越盃嗎？卓越盃的報名費用多少？讓我們來回答您的問題，或於上班時間撥打客服專線02-8665-1745。'
        }
      ]
    }
  },
  {
    path: "/FAQ/ExamContent",
    name: "ExamContent",
    // component: ExamContent,
    component:()=>import('../views/FAQ/ExamContent.vue'),
    meta: {
      title: '康軒卓越盃常見問題－考試內容一覽',
      metaTags: [
        {
          name: 'description',
          content: '才剛開學，小朋友要報考幾年級？考試範圍是什麼？若不是使用康軒版的課本，可以參加比賽嗎？讓我們來回答您的問題，或於上班時間撥打客服專線02-8665-1745。'
        },
        {
          property: 'og:description',
          content: '才剛開學，小朋友要報考幾年級？考試範圍是什麼？若不是使用康軒版的課本，可以參加比賽嗎？讓我們來回答您的問題，或於上班時間撥打客服專線02-8665-1745。'
        }
      ]
    }
  },
  {
    path: "/FAQ/Important",
    name: "Important",
    // component: Important,
    component:()=>import('../views/FAQ/Important.vue')
  },
  // 菁英榜
  {
    path: "/Elite/Personal",
    name: "Personal2",
    // component: Personal2,
    component:()=>import('../views/Elite/Personal.vue'),
    meta: {
      title: '康軒卓越盃全國菁英榜－個人菁英榜查詢',
      metaTags: [
        {
          name: 'description',
          content: '提供考試個人菁英榜查詢。請注意：卓越盃不會主動以電話通知您成績或得獎資訊！若接獲不明電話，請勿提供任何個人資料及金融資訊，有任何競賽問題，請主動撥打卓越盃「客服專線02-86651745」洽詢或至「聯絡我們」留言。'
        },
        {
          property: 'og:description',
          content: '提供考試個人菁英榜查詢。請注意：卓越盃不會主動以電話通知您成績或得獎資訊！若接獲不明電話，請勿提供任何個人資料及金融資訊，有任何競賽問題，請主動撥打卓越盃「客服專線02-86651745」洽詢或至「聯絡我們」留言。'
        }
      ]
    }
  },
  {
    path: "/Elite/Group",
    name: "Group2",
    // component: Group2,
    component:()=>import('../views/Elite/Group.vue'),
    meta: {
      title: '康軒卓越盃全國菁英榜－團體菁英榜查詢',
      metaTags: [
        {
          name: 'description',
          content: '提供考試個人菁英榜查詢。請注意：卓越盃不會主動以電話通知您成績或得獎資訊！若接獲不明電話，請勿提供任何個人資料及金融資訊，有任何競賽問題，請主動撥打卓越盃「客服專線02-86651745」洽詢或至「聯絡我們」留言。'
        },
        {
          property: 'og:description',
          content: '提供考試個人菁英榜查詢。請注意：卓越盃不會主動以電話通知您成績或得獎資訊！若接獲不明電話，請勿提供任何個人資料及金融資訊，有任何競賽問題，請主動撥打卓越盃「客服專線02-86651745」洽詢或至「聯絡我們」留言。'
        }
      ]
    }
  },
  {
    path: "/Elite/Statistics",
    name: "Statistics",
    // component: Statistics,
    component:()=>import('../views/Elite/Statistics.vue'),
    meta: {
      title: '康軒卓越盃全國菁英榜－歷年各年級分數統計查詢',
      metaTags: [
        {
          name: 'description',
          content: '提供歷年各年級分數統計查詢。請注意：卓越盃不會主動以電話通知您成績或得獎資訊！若接獲不明電話，請勿提供任何個人資料及金融資訊，有任何競賽問題，請主動撥打卓越盃「客服專線02-86651745」洽詢或至「聯絡我們」留言。'
        },
        {
          property: 'og:description',
          content: '提供歷年各年級分數統計查詢。請注意：卓越盃不會主動以電話通知您成績或得獎資訊！若接獲不明電話，請勿提供任何個人資料及金融資訊，有任何競賽問題，請主動撥打卓越盃「客服專線02-86651745」洽詢或至「聯絡我們」留言。'
        }
      ]
    }
  },
  // 卓越盃大家談
  {
    path: "/Talk/Expert",
    name: "Expert",
    // component: Expert,
    component:()=>import('../views/Talk/Expert.vue')
  },
  {
    path: "/Talk/Parent",
    name: "Parent",
    // component: Parent,
    component:()=>import('../views/Talk/Parent.vue')
  },
 
  // {
  //   path: "/Sprint",
  //   name: "SprintIntroduce",
  //   component: SprintIntroduce,
  // },
  // {
  //   path: "/ReadSprint",
  //   name: "ReadSprintIntroduce",
  //   component: ReadSprintIntroduce,
  // },
  // {
  //   path: "/Sprint/Chapter",
  //   name: "SprintChapter",
  //   // component: SprintChapter,
  //   component:()=>import('../views/Sprint/Chapter.vue')
  // },
  // {
  //   path: "/Sprint/Shared/MatchSprintChapter",
  //   name: "SprintMatchSprintChapter",
  //   // component: SprintChapter,
  //   component:()=>import('../views/Sprint/Shared/MatchSprintChapter.vue')
  // },
  // {
  //   path: "/Sprint/Shared/ReadSprintChapter",
  //   name: "ReadSprintChapter",
  //   // component: SprintChapter,
  //   component:()=>import('../views/Sprint/Shared/ReadSprintChapter.vue')
  // },

  
  {
    path: "/Camp",
    name: "CampIntroduce",
    component: CampIntroduce,
  },
  {
    path: "/Camp/Booking",
    name: "CampBooking",
    // component: CampBooking,
    component:()=>import('../views/Camp/Booking.vue')
  },
  {
    path: "/Camp/Search",
    name: "CampSearch",
    // component: CampSearch,
    component:()=>import('../views/Camp/Search.vue')
  },
  {
    path: "/Camp/Step",
    name: "CampStep",
    // component: CampStep,
    component:()=>import('../views/Camp/Step.vue')
  },
  {
    path: "/Camp/Faq",
    name: "CampFaq",
    // component: CampFaq,
    component:()=>import('../views/Camp/Faq.vue')
  },
  {
    path: "/HistoryGrade/Search",
    name: "HistoryGradeSearch",
    // component: HistoryGradeSearch,
    component:()=>import('../views/HistoryGrade/Search.vue'),
    meta: {
      title: '康軒卓越盃考生專區－考生成績查詢',
      metaTags: [
        {
          name: 'description',
          content: '提供考試成績查詢。請注意：卓越盃不會主動以電話通知您成績或得獎資訊！若接獲不明電話，請勿提供任何個人資料及金融資訊，有任何競賽問題，請主動撥打卓越盃「客服專線02-86651745」洽詢或至「聯絡我們」留言。'
        },
        {
          property: 'og:description',
          content: '提供考試成績查詢。請注意：卓越盃不會主動以電話通知您成績或得獎資訊！若接獲不明電話，請勿提供任何個人資料及金融資訊，有任何競賽問題，請主動撥打卓越盃「客服專線02-86651745」洽詢或至「聯絡我們」留言。'
        }
      ]
    }
  },
  {
    path: "/Member/HistoryGrade",
    name: "MemberHistoryGradeSearch",
    // component: MemberHistoryGradeSearch,
    component:()=>import('../views/Member/HistoryGrade.vue')
  },
  {
    path: "/Payment/ReviewPay",
    name: "ReviewPay",
    // component: ReviewPay,
    component:()=>import('../views/Payment/ReviewPay.vue')
  },
  {
    path: "/Payment/ReviewAtmPay",
    name: "ReviewAtmPay",
    // component: ReviewAtmPay,
    component:()=>import('../views/Payment/ReviewAtmPay.vue')
  },
  {
    path: "/Payment/CampPay",
    name: "CampPay",
    // component: CampPay,
    component:()=>import('../views/Payment/CampPay.vue')
  },
 
  {
    path: "/Payment/CampAtmPay",
    name: "CampAtmPay",
    // component: CampAtmPay,
    component:()=>import('../views/Payment/CampAtmPay.vue')
  },
  {
    path: "/Member/Login",
    name: "MemberSsoLogin",
    // component: CampPay,
    component:()=>import('../views/Member/Login.vue')
  },
  // {
  //   path:"/Sprint/Package",
  //   component:()=>import('../views/Sprint/Package.vue'),
  //   children:[
  //     {
  //       path:'',redirect:'/Sprint/Package/Match'
  //     },{
  //       path:'Match',
  //       component:()=>import('../views/Sprint/Tabs/MatchSprintTab.vue'),
  //       meta:{tabIndex:0}
  //     },{
  //       path:'Read',
  //       component:()=>import('../views/Sprint/Tabs/ReadSprintTab.vue'),
  //       meta:{tabIndex:1}
  //     },{
  //       path:'StrategySprintTab',
  //       component:()=>import('../views/Sprint/Tabs/StrategySprintTab.vue'),
  //       meta:{tabIndex:2}
  //     }
  //   ]
  // },
  {
    path:"/Sprint/Math",
    component:()=>import('../views/Sprint/Math.vue'),
    meta: {
      title: '康軒卓越盃衝刺包專區－課程介紹',
      metaTags: [
        {
          name: 'description',
          content: '卓越盃考前衝刺包為考試複習最佳利器，備考最強助攻，同捆一起購！從報名到應考，陪你循序漸進學習，幫助孩子打好最堅實的基礎能力。全新6大主題課程影片、300題以上練習題+3回模擬卷、智慧分析報表+高效錯題收集。'
        },
        {
          property: 'og:description',
          content:  '卓越盃考前衝刺包為考試複習最佳利器，備考最強助攻，同捆一起購！從報名到應考，陪你循序漸進學習，幫助孩子打好最堅實的基礎能力。全新6大主題課程影片、300題以上練習題+3回模擬卷、智慧分析報表+高效錯題收集。'
        }
      ]
    }
  },
  {
    path:"/Sprint/Read",
    component:()=>import('../views/Sprint/Read.vue'),
  },
  {
    path:"/Sprint/Strategy",
    component:()=>import('../views/Sprint/Strategy.vue'),
  },
  {
    path: "/About/Contact",
    name: "Contact",
    // component: News,
    component:()=>import('../views/About/Contact.vue'),
    meta: {
      title: '卓越盃升私中模擬考-聯絡我們',
      metaTags: [
        {
          name: 'description',
          content: '歡迎與我們聯繫 請加入Line官方客服 ID：@xwe6582i 網址：https://page.line.me/xwe6582i'
        },
        {
          property: 'og:description',
          content: '歡迎與我們聯繫 請加入Line官方客服 ID：@xwe6582i 網址：https://page.line.me/xwe6582i'
        }
      ]
    }
  },
];

const router = new VueRouter({
  //mode: 'history',
  //base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
