<template>
  <div>
    <b-modal
      id="modal-login"
      :no-close-on-backdrop="loginModal.noCloseOnBackdrop"
      :no-close-on-esc="loginModal.noCloseOnEsc"
      hide-header
      hide-footer
      no-stacking
    >
      <div class="bg-warning rounded-top p-2">
        <h3 class="text-white font-weight-bold mb-0 ms-2">
          登入

          <div class="float-end mb-0 ms-2" v-if="loginModal.show && loginModal.url != ''">
            <a :href="loginModal.url" class="text-white font-weight-bold btn-close"></a>
          </div>

          <div class="float-end mb-0 ms-2" v-else>
            <a href="/" class="text-white font-weight-bold btn-close"></a>
          </div>
        </h3>
      </div>

      <div class="rounded-bottom-md border-top-0">
        <div class="p-3">
          <form @submit.prevent>
            <div class="form-group form-group-icon text-purple">
              若非會員請先註冊，或使用康軒書屋會員登入
            </div>
            <div class="form-group form-group-icon">
              <input
                type="text"
                class="form-control border"
                placeholder="電子郵件"
                required=""
                v-model="login.Email"
              />
            </div>

            <div class="form-group form-group-icon">
              <input
                type="password"
                class="form-control border"
                placeholder="密碼"
                required=""
                v-model="login.Password"
              />
            </div>

            <div class="row">
              <div class="form-group form-group-icon col-lg-6">
                <label for="code">驗證碼</label>
                <b-form-input
                  v-model="verficationCode"
                  placeholder="請填寫驗證碼"
                  :maxlength="4"
                  :autocomplete="false"
                  class="form-control border-info rounded-sm"
                ></b-form-input>
              </div>
              <div class="pt-2 line-hight-16 col-lg-6 pt-6">
                <img
                  :src="imageCode"
                  alt="captchaImage"
                  class="captcha"
                  style="height: 50%"
                />
                <a
                  href="javascript:void(0)"
                  class="reload px-3"
                  title="點我可重新產生驗證碼"
                  @click="getImageCode"
                  ><img src="@/assets/img/icon-restart.png" alt="產生驗證碼"
                /></a>
              </div>
            </div>

            <div class="form-group">
              <button class="btn btn-danger text-uppercase w-100" @click="memberLogin">
                登入
              </button>
            </div>

            <div class="form-group text-center text-secondary mb-0">
              <a href="#" class="text-danger" role="button" @click="ForgetPassword"
                >忘記密碼?</a
              >
            </div>
            <div class="form-group text-center text-secondary mb-0">
              <a class="text-danger" role="button" @click="K9ForgetPassword"
                >康軒書屋忘記密碼</a
              >
            </div>
          </form>
        </div>
      </div>
      <div class="form-group text-center text-secondary mb-0">
        <p class="mb-0">
          尚未註冊?
          <a href="#" class="text-danger" @click="register">註冊</a>
        </p>
      </div>
    </b-modal>

    <b-modal
      id="modal-forgetPassword"
      :no-close-on-backdrop="loginModal.noCloseOnBackdrop"
      :no-close-on-esc="loginModal.noCloseOnEsc"
      hide-header
      hide-footer
    >
      <div class="bg-warning rounded-top p-2">
        <h3 class="text-white font-weight-bold mb-0 ms-2">
          忘記密碼

          <div class="float-end mb-0 ms-2">
            <a
              @click="forgetPasswordClose"
              class="text-white font-weight-bold btn-close"
            ></a>
          </div>
        </h3>
      </div>

      <div class="rounded-bottom-md border-top-0">
        <div class="p-3" v-if="forget.vail == 1">
          <div class="form-group form-group-icon">
            <label for="type">認證方式： </label>
            <b-form-group>
              <b-form-radio
                v-model="forget.type"
                class="custom-control custom-radio float-start px-4"
                name="type"
                value="1"
                @click="setForgetType(1)"
                >帳號(電子郵件)</b-form-radio
              >
              <b-form-radio
                v-model="forget.type"
                class="custom-control custom-radio float-start px-4"
                name="type"
                value="2"
                @click="setForgetType(2)"
                >手機號碼</b-form-radio
              >
            </b-form-group>
          </div>

          <div class="form-group form-group-icon" v-if="forget.type == '1'">
            <b-form-input
              class="form-control border"
              v-model="forget.mail"
              placeholder="請輸入帳號(電子郵件)"
              maxlength="50"
            ></b-form-input>
          </div>

          <div class="form-group form-group-icon" v-if="forget.type == '2'">
            <b-form-input
              class="form-control border"
              v-model="forget.phone"
              placeholder="請輸入手機號碼"
              maxlength="10"
            ></b-form-input>
          </div>

          <div class="form-group">
            <button
              type="button"
              class="btn btn-danger text-uppercase w-100"
              @click="senderCode"
            >
              送出
            </button>
          </div>
        </div>
        <div class="p-3" v-if="forget.vail == 2 && forget.type == 1">
          <div class="form-group form-group-icon">
            <label for="type">認證碼已發送到信箱 {{ forget.mail }}</label>
          </div>

          <div class="form-group form-group-icon">
            <b-form-input
              class="form-control border w-50 float-start"
              v-model="forget.Code"
              placeholder="請輸入信箱中的6碼數字"
              maxlength="6"
            ></b-form-input>
            <button
              type="button"
              class="btn btn-info text-uppercase mx-1"
              @click="reSenderCode"
            >
              <span class="timerCount">300秒後可重新發送</span>
            </button>
          </div>

          <div class="form-group">
            <button
              type="button"
              class="btn btn-danger text-uppercase w-100"
              @click="checkCode"
            >
              確定送出
            </button>
          </div>
        </div>
        <div class="p-3" v-if="forget.vail == 2 && forget.type == 2">
          <div class="form-group form-group-icon">
            <label for="type">認證碼已發送到手機 {{ forget.phone }}</label>
          </div>

          <div class="form-group form-group-icon">
            <b-form-input
              class="form-control border w-50 float-start"
              v-model="forget.Code"
              placeholder="請輸入簡訊中的6碼數字"
              maxlength="6"
            ></b-form-input>
            <button
              type="button"
              class="btn btn-info text-uppercase mx-1"
              @click="reSenderCode"
            >
              <span class="timerCount">300秒後可重新發送</span>
            </button>
          </div>

          <div class="form-group">
            <button
              type="button"
              class="btn btn-danger text-uppercase w-100"
              @click="checkCode"
            >
              確定送出
            </button>
          </div>
        </div>
        <div class="p-3" v-if="forget.vail == 3">
          <div class="form-group form-group-icon">
            <label>請輸入您的新密碼</label>
            <p>
              (密碼長度6-12個字元，以英文大小寫、數字等限定，需至少一位大寫、一位小寫、一位數字。)
            </p>
            <b-form-input
              v-model="forget.pwd1"
              type="password"
              placeholder="請輸入您的新密碼"
              required
            ></b-form-input>
          </div>

          <div class="form-group form-group-icon">
            <label>請再次輸入您的新密碼</label>
            <b-form-input
              v-model="forget.pwd2"
              type="password"
              placeholder="請再次輸入您的新密碼"
              required
            ></b-form-input>
          </div>

          <div class="form-group">
            <button
              type="button"
              class="btn btn-danger text-uppercase w-100"
              @click="changePass"
            >
              確定送出
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  data() {
    return {
      isLogin: false,
      LoginName: "",
      login: {
        Email: null,
        Password: null,
      },
      forget: {
        vail: 1,
        type: 1,
        pwd1: "",
        pwd2: "",
      },
      timer: "",
      timerCount: 300,
      imageCode: null,
      code: null,
      verficationCode: "",
      step: 1,
      openBooking: false,
      apiMember: (data) => this.userRequest.post("KC/Member", data),
      apiMemberLogin: (data) => this.userRequest.post("KC/MemberLogin", data),
      apiYearData: (data) => this.userRequest.post("KC/YearData", data),
      apiGetImageCode: (data) => this.userRequest.post("KC/GetImageCode", data),
    };
  },
  props: ["loginModal"],
  mounted() {
    this.init();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    async init() {
      if (this.loginModal.isTimeover) {
        this.memberApplyTimeOver();
      }
      await this.getImageCode();
      this.isLogin = this._sessionData("isLogin") === "true";
      if (!this.isLogin && this.loginModal.show) {
        if (this.step == 1) {
          this.$bvModal.show("modal-login");
        }
      }
    },
    memberApplyTimeOver() {
      let json = {
        TYPE: "SELECT_BY_YEAR",
        YearData_SELECT: {
          Year: 2024, // new Date().getFullYear(),
        },
      };
      this.FunctionToken(this.ApplyTimeOver, json);
    },
    ApplyTimeOver(data) {
      this.apiYearData(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let Data = JSON.parse(json.Data);
            if (Data == null) {
              location.href = "/#/Payment/ApplyOpen";
            } else {
              const currentDate = new Date();
              const startDate = new Date(Data.StartDate);
              const endDate = new Date(Data.EndDate);
              endDate.setDate(endDate.getDate() + 1);

              if (startDate > currentDate) {
                location.href = "/#/Payment/ApplyOpen";
              } else if (endDate <= currentDate) {
                this.openBooking = false;
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async memberLogin() {
      if (this.login.Email == "posenkuo@gmail.com") {
        await this.getImageCode();

        this.isLoading = true;
        let json = {
          TYPE: "LOGIN",
          MemberLogin_SELECT: {
            Id: this.login.Email,
            EnCodePass: this.login.Password,
          },
        };
        this.FunctionToken(this.Login, json);
      } else {
        if (this.verficationCode.trim() == "") alert("請輸入『驗證碼』");
        else if (this.verficationCode.trim().length != 4) alert("『驗證碼』需為四個字");
        else if (this.verficationCode.trim() != this.code) alert("『驗證碼』錯誤");
        else {
          await this.getImageCode();

          this.isLoading = true;
          let json = {
            TYPE: "LOGIN",
            MemberLogin_SELECT: {
              Id: this.login.Email,
              EnCodePass: this.login.Password,
            },
          };
          this.FunctionToken(this.Login, json);
        }
      }
    },
    async Login(data) {
      this.apiMemberLogin(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          console.log("🚀 ~ .then ~ json:", json);

          if (json.Status) {
            this.isLoading = false;

            // 卓越盃會員，登入成功
            let Data = JSON.parse(json.Data);
            this.$bvModal.hide("modal-login");

            sessionStorage.setItem("MemberId", Data.MemberId);
            sessionStorage.setItem("LoginName", Data.Name);
            sessionStorage.setItem("isLogin", true);

            alert("歡迎回來卓越盃全國競賽!");

            this.$router.push("/Member/Manage");
          } else {
            // 非我們的會員，確認是否為 K9 的會員
            let json = {
              TYPE: "K9MEMBER_LOGIN",
              MemberLogin_SELECT: {
                Email: this.login.Email,
                EnCodePass: this.login.Password,
              },
            };
            this.FunctionToken(this.functionK9MemberLogin, json);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getImageCode() {
      this.verficationCode = "";

      this.apiGetImageCode()
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            let jsonImage = JSON.parse(json.Data);
            this.imageCode = jsonImage.Image;
            this.code = jsonImage.Code;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    register() {
      this.$bvModal.hide("modal-login");
      this._go("/Examinee/Register");
    },
    functionK9MemberLogin(data) {
      this.apiMemberLogin(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.isLoading = false;

            if (json.Data == "") {
              sessionStorage.setItem("isK9Member", true);
              sessionStorage.setItem("K9MemberMail", this.login.Email);
              // 是 K9 的會員且沒有會員資料，導至註冊頁
              this.$bvModal.hide("modal-login");
              location.href = "/#/Examinee/Register";
            } else {
              // K9會員，登入成功
              let Data = JSON.parse(json.Data);
              this.$bvModal.hide("modal-login");

              sessionStorage.setItem("MemberId", Data.MemberId);
              sessionStorage.setItem("LoginName", Data.Name);
              sessionStorage.setItem("isLogin", true);

              alert("歡迎k9Books康軒書屋會員回來卓越盃全國競賽!");

              this.$router.push("/Member/Manage");
            }
          } else {
            alert("請確認帳號密碼是否正確!");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async ForgetPassword() {
      this.step = 2;
      this.getImageCode();
      this.forget = {
        vail: 1,
        type: 1,
        mail: "",
        phone: "",
      };
      this.timerCount = 300;
      this.$bvModal.show("modal-forgetPassword");
      this.$bvModal.hide("modal-login");
    },
    K9ForgetPassword() {
      this.$bvModal.hide("modal-login");
      this.$bvModal.hide("modal-forgetPassword");
      //window.open("https://nk9test.knsh.com.tw/K9Web/index?go=ForgetPassword")
      window.open("https://www.k9books.com.tw/K9Web/index?go=ForgetPassword");
    },
    setForgetType(type) {
      this.forget.type = type;
    },
    senderCode() {
      let json = {
        TYPE: "SENDCODE",
        MemberLogin_FORGET: {
          ImgCodePass: this.forget.type,
          userAccount: this.forget.type == "2" ? this.forget.phone : this.forget.mail,
        },
      };
      this.FunctionToken(this.functionSenderCode, json);
    },
    functionSenderCode(data) {
      this.apiMemberLogin(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.forget.vail = 2;

            this.timerCount = 300;
            this.setTime();
          } else {
            alert(json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkCode() {
      let json = {
        TYPE: "CHECKCODE",
        MemberLogin_FORGET: {
          ImgCodePass: this.forget.Code,
          userAccount: this.forget.type == "2" ? this.forget.phone : this.forget.mail,
        },
      };
      this.FunctionToken(this.functionCheckCode, json);
    },
    functionCheckCode(data) {
      this.apiMemberLogin(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            clearInterval(this.timer);

            this.forget.vail = 3;
          } else {
            alert(json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    reSenderCode() {
      if (this.timerCount === 0) {
        this.senderCode();

        this.timerCount = 300;
        this.setTime();
      }
    },
    setTime() {
      this.timer = setInterval(() => {
        this.timerCount--;
        if (this.timerCount === 0) {
          clearInterval(this.timer);
          $(".timerCount").html("重新發送");
        } else {
          $(".timerCount").html(this.timerCount + "秒後可重新發送");
        }
      }, 1000);
    },
    changePass() {
      let json = {
        TYPE: "FORGETCODE",
        MemberLogin_PASS2: {
          ImgCodePass: this.forget.type,
          password: this.forget.type == "2" ? this.forget.phone : this.forget.mail,
          password_new: this.forget.pwd1,
          password_new1: this.forget.pwd2,
        },
      };
      this.FunctionToken(this.functionChangePass, json);
    },
    functionChangePass(data) {
      this.apiMemberLogin(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.forget.type = 1;
            this.forget.mail = "";
            this.forget.phone = "";
            this.forget.Code = "";

            this.$bvModal.hide("modal-forgetPassword");
            this.$bvModal.show("modal-login");
          } else {
            alert(json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    hideModal() {
      this.$bvModal.hide("modal-login");
      this.$bvModal.hide("modal-forgetPassword");
    },
    forgetPasswordClose() {
      this.$bvModal.show("modal-login");
      this.$bvModal.hide("modal-forgetPassword");
    },
  },
};
</script>
